// @ts-expect-error Upstream bug fixed by https://github.com/nuxt/image/pull/1467 (not yet released)
import { createOperationsGenerator } from '#image'
import type { ProviderGetImage } from '@nuxt/image'
import { joinURL } from 'ufo'

const operationsGenerator = createOperationsGenerator()

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL } = {}
) => {
  if (!baseURL) {
    // also support runtime config
    baseURL = useRuntimeConfig().public.siteUrl
  }

  const operations = operationsGenerator(modifiers)

  return {
    url: joinURL(
      baseURL,
      '/api/supabase/storage',
      src + (operations ? '?' + operations : '')
    )
  }
}
